/* ========================================================================
         Utility: Material design palette generator
 ========================================================================== */
// Source from: https://codepen.io/seyedi/pen/vYYamww

@use "sass:math";

@function multiply ($fore, $back) {
  $red: math.div(red($back) * red($fore), 255);
  $green: math.div(green($back) * green($fore), 255);
  $blue: math.div(blue($back) * blue($fore), 255);
  @return rgb($red, $green, $blue);
}

@function makeColorPalette($color) {
  $light: #fff;
  $dark: multiply($color, $color);
  $palette: (
    50: mix($light, $color, 88%),
    100: mix($light, $color, 70%),
    200: mix($light, $color, 50%),
    300: mix($light, $color, 30%),
    400: mix($light, $color, 15%),
    500: mix($light, $color, 0%),
    600: mix($dark, $color, 13%),
    700: mix($dark, $color, 30%),
    800: mix($dark, $color, 46%),
    900: mix($dark, $color, 75%),
    A100: lighten(saturate(mix($dark, $color, 15%), 80%), 45.6%),
    A200: lighten(saturate(mix($dark, $color, 15%), 80%), 35.6%),
    A400: lighten(saturate(mix($dark, $color, 15%), 100%), 25.6%),
    A700: lighten(saturate(mix($dark, $color, 15%), 100%), 20.5%)
  );
  @return $palette;
}
