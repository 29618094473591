body {
    gridster {
        background: none;
    }

    gridster-preview {
        background-color: rgba($accent, 0.1)
    }

    gridster-item {
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);

        &.gridster-item-moving,
        &.gridster-item-resizing {
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
        }

        .chart {
            box-shadow: none;
            border: none;
        }
    }
}
