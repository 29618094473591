/* ========================================================================
     Component: chart
 ========================================================================== */
$app-chart-field-width: 240px !default;
$app-chart-field-list-height: 264px !default;

.chart {
    position: inherit;
}

.chart__query-field-list {
    width: 100%;
    height: $app-chart-field-list-height;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    padding-bottom: 15px;
}

.chart__query-field {
    width: $app-chart-field-width;
    padding: 15px;
}

.chart__query-field--control {
    width: 90px;
}

.control-input-down-sampling-types {
    .mat-form-field-wrapper {
        margin-bottom: -15px;
    }
}

.chart__control-group {
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-box-shadow: 0 0 15px 10px #FFFFFF;
    box-shadow: 0 0 15px 10px #FFFFFF;
    margin: 20px;
}
