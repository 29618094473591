.modal-time-range {
    .md-drppicker {
        box-shadow: none;
        margin-top: 0;
    }
}

@include media-breakpoint-up(md) {
    .modal-time-range {
        width: 600px;
        max-width: 600px;
    }
}
