/* ========================================================================
     Module: analyze Component: template-select
 ========================================================================== */

.analyze-template-select {
    font-size: 0.875rem;
}

.analyze-template-select .mat-form-field-wrapper {
    padding-bottom: 0;
}
