/* ========================================================================
     Component: page-loader
 ========================================================================== */

$app-page-loader-bg-color: lighten($accent, 8%) !default;
$app-page-loader-bar-bg-color: darken($accent, 3%) !default;
$app-page-loader-lock-ui-bg-color: rgba($white, 0.3) !default;
$app-page-loader-height: 4px !default;

.page-loader__indicator {
    height: 4px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-top: -4px;
    overflow-x: hidden;
    z-index: 3000;
}


.page-loader__indicator:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $app-page-loader-height;
    background-color: $app-page-loader-bg-color;
}

.page-loader__indicator:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -200px;
    width: 200px;
    height: $app-page-loader-height;
    background-color: $app-page-loader-bar-bg-color;
    animation: loading 2s linear infinite;
}

.page-loader__lock-ui {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: wait;
    background-color: $app-page-loader-lock-ui-bg-color;
    z-index: 2990;
}

.page-loader__progress-container {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 220px;
    height: 100%;
    justify-content: center;
    align-items: center;
}

@keyframes loading {
    from {
        left: -200px;
        width: 30%;
    }
    50% {
        width: 30%;
    }
    70% {
        width: 70%;
    }
    80% {
        left: 50%;
    }
    95% {
        left: 120%;
    }
    to {
        left: 100%;
    }
}

