@import "../../../node_modules/@fortawesome/fontawesome-free/scss/functions";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/mixins";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/variables";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/core";

$enableNgDeep: false;
$handleActiveColor: #e1e2e3;
$handleBgColor: lighten($accent, 10%);
$handleSize: 84px;
$handleWith: 10px;
$pointerWidth: 10px;
$pointerHeight: 36px;
$pointerIconWidth: 10px;
$pointerIconHeight: 12px;
$pointerIconActiveColor: $handleActiveColor;

.date-range-filter {

    position: relative;

    .date-range-filter__open-range-picker {
        position: absolute;
        right: 0;
        top: -48px;
        min-width: 580px;
        text-align:right;
    }

    @import "../../../node_modules/@angular-slider/ngx-slider/scss/ngx-slider";
    .ngx-slider {
        .ngx-slider-pointer {
            width: $pointerWidth;
            height: $pointerHeight;
            top: calc((#{$pointerHeight} - (#{$barDimension}) / 2) / -2);
            border-radius: 3px;

            &:after {
                top: calc((#{$pointerHeight} - #{$pointerIconHeight}) / 2);
                left: calc((#{$pointerWidth} - #{$pointerIconWidth}) / 2);
                @include fa-icon;
                font-weight: 900;
                font-family: "Font Awesome 6 Free";
                content: fa-content($fa-var-grip-lines-vertical);
                color: $white;
                background: none;
                font-size:11px;
                width: $pointerIconWidth;
                line-height: $pointerIconHeight;
                height: auto;
                text-align: center;
            }

            &:hover:after,
            &:focus:after {
                background: none;
                color: $pointerIconActiveColor
            }
        }

        .ngx-slider-tick {
            margin-left: $pointerWidth / 2 - $ticksWidth / 2; // for centering
        }

        .ngx-slider-selection-bar {
            background-color: rgba(lighten($handleBgColor, 10%), 0.1)
        }

        .ngx-slider-draggable {
            cursor: col-resize;
        }
    }
}
