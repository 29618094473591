/* ========================================================================
     Component: notification alert flash
 ========================================================================== */

$app-notification-alert-flash-bg-color: $body-bg !default;
$app-notification-alert-flash-offset-bottom: 0 !default;

.notification-alert-flash {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    @include z-index(notification-alert-flash);
}

.notification-alert-flash__container {
    width: 100%;
    height: 100%;
    padding: 3rem 2rem 1.5rem;
    text-align: center;
    background: linear-gradient(0deg, rgba($app-notification-alert-flash-bg-color, 1) 0%, rgba($app-notification-alert-flash-bg-color, 0) 100%);

    .alert {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
}

@include media-breakpoint-up(md) {
    .notification-alert-flash {
        margin-bottom: 0;
        padding-left: $aside-wd;
    }

    .aside-collapsed .notification-alert-flash {
        padding-left: $aside-wd-collapsed;
    }
}
