/* ========================================================================
     Component: process-loader
 ========================================================================== */

$hy-process-loader-overlay-bg-color: rgba($white, 0.4) !default;

.process-loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $hy-process-loader-overlay-bg-color;
  z-index: 500;
}

.process-loader__content {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 500px;
    justify-content: center;
    align-items: center;
}

.process-loader--active {
  display: block;
}
