/* ========================================================================
     Component: process
 ========================================================================== */

@import '~loaders.css/src/mixins';

$hy-process-loader-bg-color: $accent !default;
$hy-process-loader-size: 24px !default;

.process {
    @include balls();
    @include global-animation();
    background-color: $hy-process-loader-bg-color;
    height: $hy-process-loader-size;
    width: $hy-process-loader-size;
    animation: ball-scale 1s 0s ease-in-out infinite;
    margin: 0 auto;
    padding: 0;
}
