/* ========================================================================
     Component: unit-param-tree
 ========================================================================== */

.unit-param-tree {
    border-top: 1px solid #d1d4d7;
}

.unit-param-tree ul {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.unit-param-tree__node {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.unit-param-tree__node:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.025);
}

.unit-param-tree__node--expanded {
    border-bottom: 1px solid #d1d4d7;
}

.unit-param-tree__node--new {
    color: $green;
}

.unit-param-tree__node--deleted {
    color: $danger;
}

.unit-param-tree .unit-param-tree__node[role=treeitem]:not(.mat-nested-tree-node) {
    padding-left: 40px;
}

.unit-param-tree .mat-nested-tree-node div[role=group] {
    padding-left: 30px;
}

.unit-param-tree div[role=group] > .mat-tree-node {
    padding-left: 30px;
}

.unit-param-tree__label {
    display: inline-block;
    min-width: 150px;
}

.unit-param-tree__value {
    color: $primary;
}

@include media-breakpoint-up(md) {
    .unit-param-tree__label {
        min-width: 250px;
    }
}
