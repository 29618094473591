// -------------------------
// Z-index utility
// -------------------------

$z-index: () !default;

@function z-index($key) {
    @return map-get($z-index, $key);
}

@mixin z-index($key) {
    z-index: z-index($key);
}

